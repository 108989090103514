@import '~antd/dist/antd.css';

.container-small {
	max-width: 800px;
	padding: 0 15px;
	margin: 0 auto;
}
.container {
	max-width: 1200px;
	padding: 0 15px;
	margin: 0 auto;
}
.warning-color {
	color: #fa8c16;
}

.cancel-color {
	color: #fc625d;
}

.print-export-product {
	display: none;
}

.w-full {
	width: 100%;
}
.text-center {
	text-align: center;
}

.hover-blue:hover {
	color: #1890ff;
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-move {
	cursor: move;
}

.underline {
	text-decoration: underline;
}

.text-blue {
	color: #4169e1;
}

.mt-50 {
	margin-top: 50px;
}

.mr-5 {
	margin-right: 5px;
}

.ml-5 {
	margin-left: 5px;
}

.mr-10 {
	margin-right: 10px;
}

.print-source {
	display: none;
}

.flex {
	display: flex;
}

.hover-text-yellow {
	&:hover {
		color: #ffcc33 !important;
	}
}

@media print {
	.print-source {
		position: relative;
		height: 100%;
		page-break-after: always;
		display: block;
		&-content {
			width: 50%;
		}
	}
}

@media print {
	.print-export-product {
		display: block;
		&-title {
			text-align: center;
		}
		&-date {
			display: flex;
			justify-content: flex-end;
		}
		&-table {
			table {
				page-break-inside: auto;
			}
			tr {
				page-break-inside: avoid;
			}
		}
	}
}
