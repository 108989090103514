#erp-v2 {
	.trigger {
		padding: 0 24px;
		font-size: 18px;
		line-height: 64px;
		cursor: pointer;
		transition: color 0.3s;
		&:hover {
			color: #1890ff;
		}
	}
	.sider-layout-sidebar {
		overflow: auto;
		height: 100vh;
		position: fixed;
		left: 0;
	}
	.logo {
		display: flex;
		justify-content: center;
		align-items: center;
		a {
			display: flex;
		}
		img {
			height: 48px;
		}
	}

	.site-layout .site-layout-background {
		&.content {
			min-height: calc(100vh - 60px);
			overflow: initial;
			max-width: 1200px;
			width: 100%;
			margin: 16px auto;
		}
	}

	.site-layout-true .site-layout-background {
		&.content {
			min-height: calc(100vh - 60px);
			overflow: initial;
			max-width: 1200px;
			width: 100%;
			margin: 16px auto;
		}
	}
	.notice {
		padding-left: 14px;
		cursor: pointer;
		display: none;
	}
	.header {
		position: relative;
		height: auto;
		line-height: normal;
		padding: 8px 0;
		background-color: #33363e;
		.no-data {
			text-align: center;
			padding: 20px 0;
			color: white;
		}

		.input-search-mb {
			display: none;
		}
		.input-search {
			width: 500px;
			background-color: #22252b;
			border: 1px solid #22252b;
			border-radius: 30px;
			margin-left: 0 !important;
			cursor: pointer;
			.ant-input-prefix {
				color: #766cd6;
				font-weight: bold;
				margin-right: 30px;

				.anticon {
					font-size: 20px;
					font-weight: bold;
					display: flex;
				}
			}
			.ant-input-suffix {
				svg {
					fill: #d1d1d1;
					width: 14px;
					height: 14px;
				}
			}
			input {
				background-color: #22252b;
				color: white;
			}
		}
		.account-dropdown {
			img {
				width: 30px;
				height: 30px;
			}
		}

		.divider {
			width: 2px;
			height: 30px;
			background: #bababa;
			margin-left: 70px;
		}

		.account-button {
			display: flex;
			justify-content: center;
			align-items: center;

			margin-left: auto;
			background-color: #22252b;
			border-color: #22252b;
			padding: 20px 15px;
			border-radius: 30px;

			img {
				width: 25px;
				height: 25px;
				margin-right: 10px;
			}
			strong {
				color: white;
				margin-right: 10px;
			}
			.anticon {
				color: white;
			}
		}
		.header-left {
			display: flex;
			flex-direction: row;
			align-items: center;
			position: relative;
		}
		.header-menu {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-left: 40px;
			margin-right: 25px;
			cursor: pointer;
		}
		.header-show-menu {
			position: relative;
			&::before {
				z-index: 1;
				content: '';
				position: absolute;
				bottom: -12px;
				left: 12px;
				background-image: url('../assets/images/ic_arrow_up.png');
				width: 16px;
				height: 10px;
				background-repeat: no-repeat;
				background-size: contain;
				animation: fadeIn 0.3s ease-in;
			}
		}
		.header-main-menu {
			top: calc(100% + 8px);
			left: 180px;
			position: absolute;
			z-index: 2;
			width: 870px;
			border-radius: 5px;
			background: #22252b;
			display: none;
			max-height: calc(100vh - 80px);
			overflow: auto;
			&.active {
				display: block;
				animation: fadeIn 0.3s ease-in;
			}
			.search-mb {
				display: none;
			}
			.menu {
				background: #22252b;
				padding: 16px 10px;
				border-radius: 5px;
				line-height: 1.5;
				.ant-tabs {
					.ant-tabs-content-holder {
						border-left: 1px solid #3d3d3d;
						.ant-tabs-tabpane {
							padding-left: 50px;
						}
					}
					.ant-tabs-nav {
						.ant-tabs-nav-wrap {
							padding-left: 16px;
							.ant-tabs-tab {
								margin-top: 0;
								padding-left: 0;
								.ant-tabs-tab-btn {
									text-align: left;
									width: 100%;
									color: #bababa;
									font-weight: 500;
									font-size: 16px;
									position: relative;
									.tab {
										&__item {
											display: flex;
											align-items: center;
											transition: all 0.3s;
											&:hover {
												color: #766cd6;
											}
										}
										&__img {
											padding-right: 10px;
										}
									}
								}
								&.ant-tabs-tab-active {
									.ant-tabs-tab-btn {
										color: #766cd6;
									}
								}
								.ant-divider {
									background: #3d3d3d;
									position: absolute;
									margin: 0;
									bottom: -10px;
								}
							}
							.ant-tabs-ink-bar {
								background-color: #766cd6;
							}
						}
					}
				}
				&__item {
					display: flex;
					align-items: center;
					margin-bottom: 25px;
					.item {
						&__img {
							cursor: pointer;
							margin-right: 10px;
							display: flex;
							img {
								width: 16px;
							}
							&:hover svg {
								fill: #ffcc33;
							}
						}
						&__content {
							font-weight: 600;
							cursor: pointer;
							.content {
								&__top {
									font-size: 16px;
									color: #bababa;
									transition: all 0.3s;
									&:hover {
										color: #766cd6;
									}
								}
								&__bottom {
									font-size: 14px;
									color: #767676;
									transition: all 0.3s;
									&:hover {
										color: #766cd6;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.header {
			.header-menu {
				margin-left: 15px;
				span {
					display: none;
				}
			}
			.divider {
				margin-left: 30px;
			}
			.input-search {
				width: 300px;
			}
			.header-main-menu {
				left: 0;
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 767px) {
		.notice{
			display: block;
		}
		.header {
			padding: 8px 0;
			.header-menu {
				img {
					width: 25px;
					height: 25px;
				}
			}
			.header-show-menu {
				position: relative;
				&::before {
					z-index: 1;
					content: '';
					position: absolute;
					bottom: -16px;
					left: 5px;
					background-image: url('../assets/images/ic_arrow_up.png');
					width: 16px;
					height: 10px;
					background-repeat: no-repeat;
					background-size: contain;
				}
			}
			.logo {
				img {
					height: 36px;
				}
			}
			.divider {
				margin-left: 16px;
			}
			.account-button {
				margin-right: 0;
				margin-left: 16px;
				padding: 16px 8px;
				img {
					display: none;
				}
				&__prevText {
					display: none;
				}
			}
			.img-search {
				width: 25px;
			}
			.header-main-menu {
				border-radius: 0;
				height: calc(100vh - 52px);
				max-height: initial;
				.search-mb {
					display: flex;
					align-items: center;
					padding: 8px 16px;
					margin-bottom: 16px;
					border-bottom: 1px solid #5f5f5f;
					position: sticky;
					top: 0;
					left: 0;
					z-index: 9999;
					background-color: #22252b;
					.input-search {
						width: 100%;
						background: #33363e;
						.ant-input-prefix {
							margin-right: 16px;
						}
						input {
							font-size: 14px;
							background: #33363e;
						}
					}
					&__close {
						margin-left: 16px;
						color: #766cd6;
						font-weight: 600;
						font-size: 14px;
						cursor: pointer;
					}
				}
				.menu-result {
					padding: 0 16px;
				}
				.menu {
					background: #22252b;
					padding: 0 0px 8px;
					border-radius: 0px;
					line-height: 1.5;
					.ant-tabs {
						.ant-tabs-content-holder {
							padding: 0 14px;
							border: none;
							.ant-tabs-tabpane {
								padding-left: 0;
							}
						}
						.ant-tabs-nav {
							&::before {
								border-color: #5f5f5f;
							}
							.ant-tabs-nav-wrap {
								padding-left: 0;
								display: block;
								.ant-tabs-nav-list {
									justify-content: space-between;
								}
								.ant-tabs-tab {
									margin: 0 16px;
									.ant-divider {
										display: none;
									}
									.ant-tabs-tab-btn {
										font-size: 14px;
										.tab__img {
											display: none;
										}
										.tab__item {
											padding: 0 !important;
										}
									}
								}
							}
						}
					}
					&__item {
						margin-bottom: 12px;
						.item {
							&__img {
								&:hover svg {
									fill: currentColor;
								}
							}
							&__content {
								.content {
									&__top {
										font-size: 14px;
										&:hover {
											color: #bababa;
										}
									}
									&__bottom {
										&:hover {
											color: #767676;
										}
									}
								}
							}
						}
					}
				}
			}
			.search {
				margin-left: auto;
				.input-search {
					display: none;
				}
				.input-search-mb {
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					.ant-btn {
						background-color: transparent;
						border: none;
						margin: 0;
						padding: 0;
						display: flex;
						align-items: center;
						justify-content: center;
						box-shadow: none;
					}
				}
			}
		}
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
.dropdown-account {
	background-color: #22252b;
	li {
		color: #e8e8e8;
		font-weight: 600;
		padding: 5px 24px;
	}
}
